import React from "react";
import { connect, useSelector } from "react-redux";

import { FrequentQuestionsQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { BreadCrumbBanner, Subscription } from "../../components/common";

import { Accordion } from "react-bootstrap";
import BANNER from "../../images/siteImages/faqs/FAQ_BANNER.jpg";
import PaymentSlider from "./PaymentSlider";

const OurMission = () => {
  const language = useSelector((state) => state.language);
  const { page_heading, questionsbox, banner_image } =
    FrequentQuestionsQuery(language);
  return (
    <>
      <Layout>
        <BreadCrumbBanner banner_image={BANNER} />
        <section className="sectionbg p-top-100 p-bottom-110 faqs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-5">
                  <div className="divider divider-simple text-center">
                    <h3>{page_heading.text}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-styles accordion--one">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="accordion accordion_one" id="accordion_one">
                    <div className="accordion-single">
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        {questionsbox.map((item, index) => (
                          <Accordion.Item key={`acc-${index}`} eventKey={index}>
                            <Accordion.Header>
                              {item.question.text}
                            </Accordion.Header>
                            <Accordion.Body>{item.answer.text}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
